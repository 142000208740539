import { WebHttpRequestOptions } from '@am92/web-http'
import { asHttp } from '~/src/Configurations/WebHttp'
import serviceActionCreator from '~/src/Redux/serviceActionCreatorWithTokenRotation'
import { initiateSSOActions, initiateSSOServiceName } from '../../Actions'

async function ssoInitiate(initiatePayload: any) {
  const options: WebHttpRequestOptions = {
    url: '/sso/initiate',
    method: 'POST',
    data: initiatePayload
  }

  const response = await asHttp.request(options)
  const { data: body } = response
  const { data } = body
  return data
}

const ssoInitiateAction = serviceActionCreator(initiateSSOActions, ssoInitiate)

export default ssoInitiateAction
export { initiateSSOServiceName }
