import * as React from 'react'

import BottomStagger, { IBottomStaggerProps } from './BottomStagger'
import Dialog, { IDialogProps } from './Dialog'

export interface IErrorDialogProps {
  dialog: IDialogProps
  bottomStagger?: IBottomStaggerProps
  primaryButtonClick: () => void
  secondaryButtonClick: () => void
  tertiaryButtonClick?: () => void
  onClose: () => void
}

export default class ErrorDialog extends React.PureComponent<IErrorDialogProps> {
  render() {
    const {
      dialog,
      bottomStagger,
      primaryButtonClick,
      secondaryButtonClick,
      tertiaryButtonClick,
      onClose
    } = this.props
    const bottomStaggerProps = bottomStagger || dialog
    return [
      <Dialog
        {...dialog}
        primaryButtonClick={primaryButtonClick}
        secondaryButtonClick={secondaryButtonClick}
        tertiaryButtonClick={tertiaryButtonClick}
        onClose={onClose}
      />,
      <BottomStagger
        {...bottomStaggerProps}
        primaryButtonClick={primaryButtonClick}
        secondaryButtonClick={secondaryButtonClick}
        tertiaryButtonClick={tertiaryButtonClick}
        onClose={onClose}
      />
    ]
  }
}
