import { DsStack, DsTypography } from '@am92/react-design-system'
import React from 'react'

const CouldNotFindUser: React.FunctionComponent = () => {
  return (
    <DsStack
      sx={{
        flexDirection: 'column',
        gap: 'var(--ds-spacing-quickFreeze)',
        alignItems: { md: 'center', xs: 'unset' }
      }}
    >
      <DsTypography variant='bodyRegularLarge'>
        Your journey starts here!
      </DsTypography>
      <DsStack
        sx={{ flexDirection: 'row', gap: 'var(--ds-spacing-deepFreeze)' }}
      >
        <DsTypography
          variant='headingBoldItalicMedium'
          color={'var(--ds-colour-actionPrimary)'}
        >
          ‘investor app’
        </DsTypography>
        <DsTypography variant='headingBoldMedium'>by Axis Direct.</DsTypography>
      </DsStack>
    </DsStack>
  )
}

export default CouldNotFindUser
