import { getAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import {
  GA_API_KEY,
  GA_AUTH_DOMAIN,
  GA_PROJECT_ID,
  GA_STORAGE_BUCKET,
  GA_MESSAGING_SENDER_ID,
  GA_APP_ID,
  GA_MEASUREMENT_ID
} from '../Configurations/env'

export const googleAnalytics = () => {
  const firebaseConfig = {
    apiKey: GA_API_KEY,
    authDomain: GA_AUTH_DOMAIN,
    projectId: GA_PROJECT_ID,
    storageBucket: GA_STORAGE_BUCKET,
    messagingSenderId: GA_MESSAGING_SENDER_ID,
    appId: GA_APP_ID,
    measurementId: GA_MEASUREMENT_ID
  }

  const app = initializeApp(firebaseConfig)
  const analytics = getAnalytics(app)
}
